/*eslint-disable*/
import {TMUCall} from "@/TMUCall";
import tmuWidget from "@/tmuWidget";
export default {
    data() {
        return {
            currentStream: null,
            audioStream: null,
            record: null,
            recordingTimeout: null,
            isRecording: false,
            isSpeakerOn: true,
            speech: null,
            isConvMenuReady: false,
            isWidgetReady: false,
            isEmergencyHandled: false,
            isEmergencyCalledByIcon: false,
            isEmergencyCallStarted: false,
            isRecordingAllowed: true,
            speechGeneratedText: '',
            recognition: null,
            speechAudio: null,
            STTMediaRecorder: null,

            isBotTyping: false,
            isFeedbackShown: false,
            feedbackData: null,
            message: '',
            callData: {},
            feedbackText: "",
            emailText: "",
            isFeedbackSending: false,
            isEmailModalShown: false,
            isDownloadModalShown: false,
            isGuestWaiting: false,
            waitingGuestTracker: null,
            guestSecondsOfWaiting: 120000, //2 min
            isRequestSent: false,
            emailSendInfo: null,
            isEmailSent: false,
            isFeedbackSent: false,
            currentFeedbackQuestion: 0,
            isDownloadSent: false,
            isDownloadSending: false,
            downloadSendInfo: null,
            downloadEmailText: "",
            waitingCounter: 0,
            refreshBotSessionInterval: null,
            requestedInputFormat: '',
            selectedChatFile: null,
            isChatInputEnabled: true,
            waitForNLPQuestion: false,
            voices: null,
            speechLang: null,
            mustTranslate: false,
            targetVoiceCode: null,
            supportedLanguagesCode: [],
            isTranscriptModalShown: false,
            currentTranscriptStep: 0,
            TranscriptSpeakerNumber: 1,
            currentSpeaker: 0,
            speakersMap: [],
            TranscriptionSocket: null,
            TranscriptionStream: null,
            TranscriptionAudioStream: null,
            TranscriptionWorker: null,
            isTranscriptionActive: false,
            isTranscriptionConnecting: false,
            transcriptMessages: [],
            redirectToChatGPT: false,
            mediaRecorder: null,
            isInternalTTSSet: false,
            lastNode: undefined,
            elementsOnHold: [],
            chatCommends: {
                '/exit': {
                    type: 'STOP_CHAT_GPT',
                }
            },
            UIText: {
                audioNoticeModal:{replacement: ["", ""], header: "", body: {notices: ["", ""]}, footer: {action: ""}},
                emergencyModal:{header: "", body: {beforeCallStarted: "", afterCallStarted: "", recordNotice: ""}, footer: {action: "", cancel: ""}},
                transcriptionModal: {header: "", body: {speakersNumber: "", speakerInfoRequest: "", name: "", voice: ""}, footer: {action: ""}},
                call: {callClosed: "", keepWaitingBtn: "", callError: "", agentDelay: "", userWaitingSuccess: "", userWaitingFailure: ""},
                chat: {chatEndLabel: "", botTimeout: "", restartChatRequest: ""},
                transcriptEmailRequest: "", chatGPTFail: "", askForWaiting: "", feedbackAnswerInput: "", authenticationRequest: "",
                askEmail: "", emailConvSuccess: "", emailConvFailure: "", send: "", cancel: "", next: "", back: "", message_text_hint: ""
            },
            voicesLangMap: {
                en: {speechCode: 'en-US',  speechName: 'Samantha'},
                fr: {speechCode: 'fr-CA', speechName: 'Amelie'},
                ar: {speechCode: 'ar-SA', speechName: 'Maged'},
                gre: {speechCode: 'el-GR', speechName: 'Melina'}
            },
            isLanguagesListButtonsShowing: false,
            disableChatInput: false,
            wizardInput: null
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", () => {
        if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
    })},
    mounted(){
        if(this.$el) {
            if(this.globals.isCustom) {
                document.body.style.overflow = 'hidden';
                this.$el.classList.add('is-custom');
                this.$el.removeEventListener('click', this.handleClickContainer);
                this.$el.addEventListener('click', this.handleClickContainer);
                this.$el.removeEventListener('scroll', this.handleScrollContainer);
                this.$el.addEventListener('scroll', this.handleScrollContainer);
                this.globals.initContainers();
            }
            this.$el.classList.remove('display-none');
        }
        this.globals.BotPage = this;
        this.globals.active_page = 'bot';
        if(!this.globals.botInfo.isAudioEnabled && !this.globals.botInfo.show_emergency_call_modal) this.isWidgetReady = true;
        if(!this.globals.botInfo.show_emergency_call_modal) this.isEmergencyHandled = true;
        this.speechLang = this.globals.botInfo.language_code || 'en';
        this.UIText = this.globals.botInfo.UIText;
        for(let i = 0; i < this.UIText.audioNoticeModal.body.notices.length; i++){
            this.UIText.audioNoticeModal.body.notices[i] =
                this.UIText.audioNoticeModal.body.notices[i].replace(/{{(\d+)}}/g, (_, index) => this.UIText.audioNoticeModal.replacement[index]);
        }
        this.globals.botInfo.languages = Array.isArray(this.globals.botInfo.languages) ? this.globals.botInfo.languages : [];
        this.mustTranslate = this.globals.botInfo.allow_translation && this.globals.botInfo.language_code !== this.speechLang;
        this.supportedLanguagesCode = this.globals.botInfo.languages.map(lang => lang.code);
        this.globals.botInfo.chat_direction = 'ltr'
        const targetLang = this.globals.botInfo.languages.find(l => l.code === this.speechLang)
        if(targetLang && !!+targetLang.is_rtl) this.globals.botInfo.chat_direction = 'rtl';
        if(this.globals.botInfo.isAudioEnabled){
            if(!("speechSynthesis" in window) && !this.globals.botInfo.use_ext_tts_api) this.globals.botInfo.isAudioEnabled = false;
            else {
                if(!this.globals.botInfo.use_ext_stt_api) this.setupSpeech();
                if(!this.globals.botInfo.use_ext_tts_api) {
                    if ("speechSynthesis" in window){
                        if('onvoiceschanged' in window.speechSynthesis)
                            window.speechSynthesis.onvoiceschanged = this.setVoice;
                        this.setVoice();
                        this.isInternalTTSSet = true;
                    }
                }
                if(this.globals.botInfo.isAudioModalShowed && !this.globals.botInfo.show_emergency_call_modal) {
                    this.isWidgetReady = true;
                    // this.widgetServices.setAudioToReady()
                    setTimeout(() => {
                        if(this.$refs['microphone-btn']){
                            this.$refs['microphone-btn'].removeEventListener('pointerdown', this.startRecording, false);
                            this.$refs['microphone-btn'].addEventListener('pointerdown', this.startRecording, false);
                            this.$refs['microphone-btn'].removeEventListener('pointerout', this.stopRecording, false);
                            this.$refs['microphone-btn'].addEventListener('pointerout', this.stopRecording, false);
                            this.$refs['microphone-btn'].ontouchstart = function(e) { e.preventDefault();}
                        }
                    })
                    // if(Array.isArray(this.globals.botInfo.ads)){
                    //     this.globals.botInfo.ads = this.globals.botInfo.ads.map(ad => ({...ad, isActive: false}))
                    //     for(const ad of this.globals.botInfo.ads){
                    //         setTimeout(() => { ad.isActive = true; }, (+ad.timer ? +ad.timer : 10) * 1000)
                    //     }
                    // }
                }
            }
        }
    },
    beforeDestroy() {
        if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
    },
    beforeRouteLeave(to, from, next) {
        if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
        next();
    },
    methods: {
        loadChatHistory() {
            this.widgetServices.loadChatHistory((data, error) => {
                if (error || !data) return;
                const response = data;
                if(response.success) {
                    if(Array.isArray(response.conversation) && response.conversation.length)
                        this.addResponseToChat(response.conversation, true).then(() => {}).catch(() => {});
                    else {
                        this.globals.botInfo.save_chat = false;
                        this.startChat();
                    }
                } else console.log(response.message)
            })
        },
        handleClickContainer(e){
            if(!e || !this.globals.isCustom)
                return;
            if(e.target && !e.target.classList.contains('textInput')) {
                // this.globals.focusOnMainInput();
            }
        },
        handleScrollContainer(e){
            if(!e.isTrusted)
                return;
            setTimeout(()=>{
                if(!this.globals.chatBoxContainer)
                    this.globals.chatBoxContainer = document.getElementById(this.globals.chatBoxContainerId);
                //HERE handle buttons potentially
            });
            setTimeout(()=>{
                if(!this.globals.historyContainer)
                    this.globals.historyContainer = document.getElementById(this.globals.historyContainerId);
                if(!this.globals.activeContainer)
                    this.globals.activeContainer = document.getElementById(this.globals.activeContainerId);
                if(Math.abs(e.target.scrollTop - this.globals.historyContainer.clientHeight) > 80)
                    this.globals.handleHistoryVisible(e);
            })
        },
        startRecordingForExtAPI() {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    const micRecordChunks = [];
                    this.STTMediaRecorder = new MediaRecorder(stream);
                    // this.STTMediaRecorder.ondataavailable = e => {  }
                    this.STTMediaRecorder.addEventListener("dataavailable", (e) => {
                        /* on stop code here*/
                        micRecordChunks.push(e.data);
                        stream.getAudioTracks().forEach(track => track.stop());
                        const audioStream = new Blob(micRecordChunks, {type: 'audio/wav'});
                        this.widgetServices.stt({
                            audioFile: audioStream,
                            lang: this.speechLang,
                            cb: (data, error) => {
                                if(error) console.log(error);
                                try{
                                    if (!data || !data.success || typeof data.transcript !== 'string' || !data.transcript.trim()) return;
                                    if (this.mustTranslate && !this.redirectToChatGPT) {
                                        this.translate({texts: [data.transcript], is_reversed: true})
                                            .then(translateResult => {
                                                if (Array.isArray(translateResult) && translateResult.length && translateResult[0].translation) {
                                                    this.talk(translateResult[0].translation);
                                                } else this.talk(data.transcript);
                                            })
                                            .catch(error => { console.log(error) });
                                    } else this.talk(data.transcript);
                                    if (this.globals.messages[this.globals.messages.length - 1] && this.globals.messages[this.globals.messages.length - 1].choices) {
                                        this.globals.messages[this.globals.messages.length - 1].choices.length = 0;
                                        this.globals.messages[this.globals.messages.length - 1].choicesSettings = null;
                                    }
                                    this.globals.addMessage({
                                        type: 'text',
                                        message: data.transcript,
                                        date: new Date().toLocaleTimeString('en-US'),
                                        from: 'user'
                                    });
                                    if (this.waitForNLPQuestion || this.redirectToChatGPT) {
                                        this.isChatInputEnabled = false;
                                        // this.widgetServices.hideAudioIcons()
                                        this.isBotTyping = true;
                                        this.waitForNLPQuestion = false
                                    }
                                    setTimeout(() => { this.scrollDown(); })
                                } catch (e) { console.log(e) }
                            }
                        })
                    })
                    // this.STTMediaRecorder.onstop = () => {}
                    this.STTMediaRecorder.start();
                })
                .catch(error => { console.log(error)});
        },
        stopChatGPT(continueConversation = true){
            this.redirectToChatGPT = false;
            if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
            if (continueConversation && this.isChatInputEnabled && this.globals.isChatEnabled) { this.resumeFlow(); }
        },
        showTranscriptModal() {
            this.isTranscriptModalShown = true;
        },
        closeTranscriptModal(continueConversation = true) {
            this.stopTranscription();
            this.disconnectTranscriptionSocket();
            this.isTranscriptModalShown = false;
            this.currentTranscriptStep = 0;
            this.TranscriptSpeakerNumber = 1;
            this.currentSpeaker = 0
            this.speakersMap = [];
            this.transcriptMessages = [];
            this.TranscriptionSocket = null;
            this.TranscriptionStream = null;
            this.TranscriptionAudioStream = null;
            this.TranscriptionWorker = null;
            this.isTranscriptionConnecting = false;
            this.isTranscriptionActive = false;
            if (continueConversation && this.globals.valueToPassWaiting && this.isChatInputEnabled && this.globals.isChatEnabled) {
                this.resumeAfterFlowPause(this.globals.valueToPassWaiting);
                this.globals.valueToPassWaiting = '';
            }
        },
        nextTranscriptStep() {
            if (this.currentTranscriptStep === 0) {
                this.currentTranscriptStep = 1;
                this.setupTranscription().then(() => {}).catch(() => {});
                return;
            }
            if (this.currentSpeaker < this.speakersMap.length - 1) this.currentSpeaker++;
        },
        startTranscription(){
            this.currentTranscriptStep = 2;
        },
        connectToTranscriptionSocket(){
            return new Promise((resolve, reject) => {
                this.TranscriptionSocket = io(process.env.VUE_APP_TRANSCRIPTION_SOCKET_URL);
                this.TranscriptionSocket.on('connect', () => {
                    this.TranscriptionSocket.on('streamWords', data => {
                        if(!Array.isArray(data) || !data.length) return;
                        if(this.speakersMap.length && this.speakersMap[this.currentSpeaker].isRecording){
                            const targetVoice = data.find(speech => speech.speaker === this.currentSpeaker);
                            if(targetVoice) {
                                this.speakersMap[this.currentSpeaker].isRecording = false;
                                this.speakersMap[this.currentSpeaker].isRecorded = true;
                                this.speakersMap[this.currentSpeaker].notice = `The speaker ${this.currentSpeaker + 1} voice is recognized`;
                            }
                        } else {
                            if(this.currentTranscriptStep === 2){
                                for(const speech of data){
                                    if(this.speakersMap[speech.speaker]){
                                        this.transcriptMessages.push({name: this.speakersMap[speech.speaker].speakerName, transcript: speech.transcript, date: new Date().toLocaleTimeString('en-US')})
                                    }
                                }
                            }
                        }
                    })
                    resolve()
                });
                this.TranscriptionSocket.on('connect_error', error => {
                    console.log(error)
                    reject(error)
                })
            })
        },
        startTranscriptSocket(){
            return new Promise((resolve, reject) => {
                this.TranscriptionSocket.emit('startTranscript', this.speechLang);
                this.TranscriptionSocket.on('streamReady', () => { resolve(); });
            })
        },
        stopTranscriptSocket(){ if(this.TranscriptionSocket && this.TranscriptionSocket.connected) this.TranscriptionSocket.emit('stopTranscript');},
        setupTranscriptionMedia(){
            return new Promise((resolve, reject) => {
                navigator.mediaDevices
                    .getUserMedia({audio: true}).then(async stream => {
                        this.TranscriptionStream = stream;
                        const audioConstraint = {deviceId: 'default', channelCount: 2, echoCancellation: true, sampleRate: 16000};
                        const AudioContext = window.AudioContext || window['webkitAudioContext'];
                        if(navigator.userAgent.indexOf("Firefox")>-1) delete audioConstraint.sampleRate;
                        const audioContext = new AudioContext(audioConstraint);
                        try {
                            await audioContext.audioWorklet.addModule('pcmWorker.js');
                            const audioStream = audioContext.createMediaStreamSource(stream);
                            this.TranscriptionAudioStream = audioStream;
                            const filter = audioContext.createBiquadFilter();
                            filter.type = 'peaking';
                            const pcmWorker = new AudioWorkletNode(audioContext, "pcm-worker", { outputChannelCount: [1] });
                            this.TranscriptionWorker = pcmWorker;
                            audioStream.connect(filter);
                            filter.connect(pcmWorker)
                            pcmWorker.port.onmessage = (event) => {
                                if (this.TranscriptionSocket && this.TranscriptionSocket.connected) this.TranscriptionSocket.emit('streamVoice', event.data)
                            }
                            pcmWorker.port.start();
                            resolve(1);
                        } catch(error) { reject(error); }
                    }).catch(error => {reject(error)});
            })
        },
        setupTranscription(){
            this.isTranscriptionConnecting = true;
            return this.connectToTranscriptionSocket()
                .then(() => this.startTranscriptSocket())
                .then(() => this.setupTranscriptionMedia())
                .then(() => {
                    for(let i = 0; i < this.TranscriptSpeakerNumber; i++){
                        this.speakersMap.push({speakerIndex: i, speakerName: '', isRecorded: false, isRecording: false, notice: ''})
                    }
                    this.isTranscriptionActive = true;
                })
                .catch((error) => { console.log(error)})
                .finally(() => { this.isTranscriptionConnecting = false; })
        },
        getSpeakerVoice(speaker){
            if(!speaker) return;
            speaker.isRecording = true;
            speaker.notice = 'Please start talking now...'
        },
        disconnectTranscriptionSocket(){
            if (!this.TranscriptionSocket || !this.TranscriptionSocket.connected) return;
            this.TranscriptionSocket.close();
            this.TranscriptionSocket = null;
        },
        stopTranscription() {
            this.stopTranscriptSocket()
            if (this.TranscriptionAudioStream) this.TranscriptionAudioStream.disconnect()
            if(this.TranscriptionWorker) this.TranscriptionWorker.disconnect();
            if(this.TranscriptionStream) this.TranscriptionStream.getAudioTracks().forEach(track => { track.stop(); } )
            this.TranscriptionWorker = null;
            this.TranscriptionAudioStream = null;
            this.TranscriptionStream = null;
        },
        showEmergencyModal(){
            this.isEmergencyHandled = false;
            this.stopTTS();
            this.isEmergencyCalledByIcon = true;
        },
        toggleLanguagesListButtons() {
            this.isLanguagesListButtonsShowing = !this.isLanguagesListButtonsShowing;
        },
        updateLanguage(lang, UIText){
            this.isLanguagesListButtonsShowing = false;
            if(!this.supportedLanguagesCode.includes(lang)) return;
            if(this.speechLang === lang)
                return;
            this.speechLang = lang;
            this.UIText = UIText;
            for(let i = 0; i < this.UIText.audioNoticeModal.body.notices.length; i++){
                this.UIText.audioNoticeModal.body.notices[i] =
                    this.UIText.audioNoticeModal.body.notices[i].replace(/{{(\d+)}}/g, (_, index) => this.UIText.audioNoticeModal.replacement[index]);
            }
            this.mustTranslate = this.globals.botInfo.allow_translation && this.globals.botInfo.language_code !== this.speechLang;
            const targetLang = this.globals.botInfo.languages.find(l => l.code === this.speechLang)
            if(targetLang) this.globals.botInfo.chat_direction = !!+this.globals.botInfo.languages.find(l => l.code === this.speechLang).is_rtl ? 'rtl' : 'ltr';
            this.setVoice()
            this.getNewUser();
        },
        selectLanguageListButton(lang) {
            if(!lang || !this.globals.isCustom || !this.isLanguagesListButtonsShowing)
                return;
            tmuWidget.requestChangeLanguageFromBot(lang);
            this.isLanguagesListButtonsShowing = false;
            if(!this.supportedLanguagesCode.includes(lang)) return;
        },
        startInstantCall(){
            const callInfo = {providerId: 0, guestName: 'Guest', subject: 'Emergency call', description: `Call from ${this.globals.botInfo.origin}`, isEmergency: true};
            this.isEmergencyCallStarted = true;
            this.widgetServices.onCallRequested({
                providerId: callInfo.providerId, visitor_name: callInfo.guestName, subject: callInfo.subject, description: callInfo.description,
                cb: (data, err) => {
                    if(err || !data) return console.log("There is an error fetching the provider information");
                    else {
                        if(data.success) {
                            callInfo.provider = data.provider;
                            TMUCall.startMreCall(callInfo);
                        } else { console.log(data.message); }
                    }
                }
            })
        },
        startChat(){
            if (this.globals.botInfo.save_chat){ this.loadChatHistory(); }
            else {
                this.isRequestSent = true;
                const text = this.globals.valueToPassWaiting || '><';
                if(!this.globals.valueToPassWaiting) this.lastNode = undefined;
                this.globals.valueToPassWaiting = '';
                this.widgetServices.chatWithBot({
                    text: text,
                    ignoreSaving: true,
                    lastNode: this.lastNode,
                    cb: (data, err) => {
                        if(data) {
                            if(Array.isArray(this.globals.botInfo.ads)){
                                this.globals.isChatStarted = true;
                                this.globals.botInfo.ads = this.globals.botInfo.ads.map(ad => ({...ad, isActive: false}))
                                for(const ad of this.globals.botInfo.ads){
                                    setTimeout(() => { ad.isActive = true; }, (+ad.timer ? +ad.timer : 10) * 1000)
                                }
                            }
                            this.addToChat(data, text, true).then(() => {setTimeout(() => { this.scrollDown(); })}).catch(err => console.error(err))
                        }
                        else console.log(err)
                        this.isRequestSent = false;
                    }})
            }
        },
        initChatBot(){
            if(this.globals.botInfo.use_ext_tts_api) {
                this.speechAudio = new Audio()
                this.speechAudio.autoplay = true;
                this.speechAudio.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
                this.speechAudio.play()
            }
            if(this.isEmergencyCalledByIcon){
                this.isEmergencyCalledByIcon = false;
                this.isEmergencyHandled = true;
                this.widgetServices.showEmergencyBtn();
                return;
            }
            if(!this.isEmergencyHandled){
                this.isEmergencyHandled = true;
                if(this.globals.botInfo.show_emergency_call_icon) this.widgetServices.showEmergencyBtn();
                if(!this.globals.botInfo.isAudioEnabled || this.globals.botInfo.isAudioModalShowed){
                    this.isWidgetReady = true;
                    // this.widgetServices.setAudioToReady()
                    setTimeout(() => {
                        if(this.$refs['microphone-btn']){
                            this.$refs['microphone-btn'].removeEventListener('pointerdown', this.startRecording, false);
                            this.$refs['microphone-btn'].addEventListener('pointerdown', this.startRecording, false);
                            this.$refs['microphone-btn'].removeEventListener('pointerout', this.stopRecording, false);
                            this.$refs['microphone-btn'].addEventListener('pointerout', this.stopRecording, false);
                            this.$refs['microphone-btn'].ontouchstart = function(e) { e.preventDefault();}
                        }
                    })
                    this.startChat();
                }
                return;
            }
            if(this.globals.botInfo.isAudioEnabled && !this.globals.botInfo.isAudioModalShowed) {
                // tmuWidget.rememberAudioNoticeVisibility()
                this.globals.botInfo.isAudioModalShowed = true;
            }
            if(this.globals.botInfo.show_emergency_call_icon) this.widgetServices.showEmergencyBtn();
            try {
                navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                    stream.getAudioTracks().forEach(track => track.stop())
                    this.isWidgetReady = true;
                    // this.widgetServices.setAudioToReady()
                    setTimeout(() => {
                        if(this.$refs['microphone-btn']){
                            this.$refs['microphone-btn'].removeEventListener('pointerdown', this.startRecording, false);
                            this.$refs['microphone-btn'].addEventListener('pointerdown', this.startRecording, false);
                            this.$refs['microphone-btn'].removeEventListener('pointerout', this.stopRecording, false);
                            this.$refs['microphone-btn'].addEventListener('pointerout', this.stopRecording, false);
                            this.$refs['microphone-btn'].ontouchstart = function(e) { e.preventDefault();}
                        }
                    })
                    this.startChat();
                }).catch((error) => {
                    this.isWidgetReady = true;
                    this.startChat();
                })
            } catch (error) {
                this.isWidgetReady = true;
                this.startChat();
            }
        },
        setupSpeech(){
            const SpeechRecognition  =  window['SpeechRecognition'] || window['webkitSpeechRecognition'];
            if (!SpeechRecognition) return;
            this.recognition = new SpeechRecognition();
            // this.recognition.continuous = true;
            // this.recognition.interimResults = true;
            this.recognition.onerror = error => { console.log("Error on speech detection", error) }
            this.recognition.onend = async () => {
                if (!this.speechGeneratedText.trim()) return;
                if(this.mustTranslate && !this.redirectToChatGPT){
                    let translateResult = await this.translate({texts: [this.speechGeneratedText], is_reversed: true})
                        .catch((error) => {console.log(error)});
                    if(Array.isArray(translateResult) && translateResult.length && translateResult[0].translation) {
                        this.talk(translateResult[0].translation);
                    } else this.talk(this.speechGeneratedText);
                } else this.talk(this.speechGeneratedText);

                if(this.globals.messages[this.globals.messages.length - 1] && this.globals.messages[this.globals.messages.length - 1].choices){
                    this.globals.messages[this.globals.messages.length - 1].choices.length = 0;
                    this.globals.messages[this.globals.messages.length - 1].choicesSettings = null;
                }
                this.globals.addMessage({
                    type: 'text',
                    message: this.speechGeneratedText,
                    date: new Date().toLocaleTimeString('en-US'),
                    from: 'user'
                })

                if(this.waitForNLPQuestion  || this.redirectToChatGPT){
                    this.isChatInputEnabled = false;
                    // this.widgetServices.hideAudioIcons()
                    this.isBotTyping = true;
                    this.waitForNLPQuestion = false
                }

                this.speechGeneratedText = '';
                setTimeout(() => { this.scrollDown(); })
            }
            this.recognition.onresult = event => { this.speechGeneratedText = event.results[0][0].transcript; };
        },
        setVoice() {
            if(!this.speech) {
                this.speech = new SpeechSynthesisUtterance();
                this.speech.rate = 0.8;
            }
            if(!this.voices || !this.voices.length) this.voices = window.speechSynthesis.getVoices();
            if(!this.voices || !this.voices.length) return;

            let targetVoice = null;
            if(this.voicesLangMap[this.speechLang]) {
                targetVoice = this.voices.find(voice => voice.name.toLowerCase() === this.voicesLangMap[this.speechLang].speechName.toLowerCase());
                if(!targetVoice) targetVoice = this.voices.find(voice => voice.lang.toLowerCase() === this.voicesLangMap[this.speechLang].speechCode.toLowerCase());
            }
            if(!targetVoice) targetVoice = this.voices.find(voice => voice.lang.toLowerCase().indexOf(this.speechLang.toLowerCase() + '-') === 0);
            if(targetVoice){
                this.speech.voice = targetVoice;
                this.speech.lang = targetVoice.lang;
                this.speech.voiceURI = targetVoice.voiceURI;
                this.targetVoiceCode = this.voicesLangMap[this.speechLang] ? this.voicesLangMap[this.speechLang].speechCode : targetVoice.lang;
            }
        },
        stopSpeaker(){
            this.isSpeakerOn = false;
            this.stopTTS();
        },
        stopTTS(){
            if(this.globals.botInfo.use_ext_tts_api){
                if(this.speechAudio && !this.speechAudio.paused){
                    this.speechAudio.pause();
                    this.speechAudio.currentTime = 0;
                }
            }
            speechSynthesis.cancel();
        },
        startSpeaker(){
            this.isSpeakerOn = true;
        },
        replaceTTSPatternForSound(text){
            return text.replace(/\[\[.*?:(.*?)]]/g, '$1');
        },
        replaceTTSPatternForText(text){
            return text.replace(/\[\[(.*?):.*?]]/g, '$1');
        },
        runTextToSpeech(text){
            if(!this.isSpeakerOn) return;
            text = this.HTMLToPlainText(text);
            text = this.removeEmojis(text);
            text = this.replaceTTSPatternForSound(text);
            if (typeof text !== 'string' || !text.trim()) return
            this.stopTTS();
            if(this.globals.botInfo.use_ext_tts_api){
                this.widgetServices.tts({
                    text: text.trim(),
                    lang: this.speechLang,
                    cb: (data, error) => {
                        if(!this.isSpeakerOn) return;
                        if(error || !data || !data.success){
                            if(!("speechSynthesis" in window)) return;
                            if(!this.isInternalTTSSet) {
                                if ('onvoiceschanged' in window.speechSynthesis) window.speechSynthesis.onvoiceschanged = this.setVoice;
                                this.setVoice();
                                this.isInternalTTSSet = true;
                            }
                            this.speech.text = text;
                            window.speechSynthesis.speak(this.speech)
                            return;
                        }
                        this.speechAudio.src = 'data:audio/wav;base64,' + data.base64;
                        this.speechAudio.play()
                            .catch(error => {
                                console.log('error:', error)
                                if(!("speechSynthesis" in window)) return;
                                if(!this.isInternalTTSSet) {
                                    if ('onvoiceschanged' in window.speechSynthesis) window.speechSynthesis.onvoiceschanged = this.setVoice;
                                    this.setVoice();
                                    this.isInternalTTSSet = true;
                                }
                                this.speech.text = text;
                                window.speechSynthesis.speak(this.speech)
                            });
                    }
                })
            } else {
                this.speech.text = text;
                window.speechSynthesis.speak(this.speech)
            }
        },
        startRecording(){
            if(this.isRecording || this.disableChatInput) return;
            this.$refs['microphone-btn'].classList.add('active');
            if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
            if(this.globals.botInfo.use_ext_stt_api) this.startRecordingForExtAPI()
            else {
                if (this.recognition.ongoing) return;
                this.recognition.lang = this.voicesLangMap[this.speechLang].speechCode;
                this.recognition.start();
            }
            this.isRecording = true;
        },
        stopRecording(){
            if(!this.isRecording) return;
            this.$refs['microphone-btn'].classList.remove('active');
            setTimeout(() => {
                if(this.globals.botInfo.use_ext_stt_api){
                    if(this.STTMediaRecorder){
                        this.STTMediaRecorder.stop();
                        this.STTMediaRecorder = null;
                    }
                } else this.recognition.stop();
                this.isRecording = false;
            }, 200)
        },
        scrollDown() {
            if(!document.querySelector('.conversation')) return;
            let chatBox = document.querySelector('.conversation');
            if(!this.globals.isCustom)
                setTimeout(() => { chatBox.scrollTop = chatBox.scrollHeight; })
        },
        resumeAfterFlowPause(valueToPass = ''){
            if(!valueToPass.trim() || !this.globals.isChatEnabled) return;
            this.isRequestSent = true;
            this.widgetServices.chatWithBot({
                text: valueToPass,
                isHidden: true,
                lastNode: this.lastNode,
                cb: (data, err) => {
                    if(data){ this.addToChat(data, undefined, true).then(() => { setTimeout(() => { this.scrollDown(); })}).catch(err => console.error(err)) }
                    else console.log(err)
                    this.isRequestSent = false;
                }})
        },
        resumeFlow(){
            if(typeof this.globals.valueToPassWaiting !== 'string' || !this.globals.valueToPassWaiting.trim()) return;
            setTimeout(() => { this.scrollDown(); })
            setTimeout(() => {
                this.isRequestSent = true;
                this.widgetServices.chatWithBot({
                    text: this.globals.valueToPassWaiting,
                    isHidden: true,
                    lastNode: this.lastNode,
                    cb: (data, err) => {
                    if(data){
                        this.elementsOnHold = [];
                        this.addToChat(data, undefined, true).then(() => {
                            this.globals.valueToPassWaiting = '';
                            setTimeout(() => { this.scrollDown();})}).catch(err => console.error(err))
                    } else console.log(err);
                    this.isRequestSent = false;
                }})
            }, 1000);
        },
        async sendMessage(event) {
            if(!this.globals.isChatEnabled || !this.message.trim() || this.isBotTyping || this.disableChatInput) return;
            this.wizardInput = null;
            let message = this.message.trim();
            if (event) {
                if (event.shiftKey) {
                    event.currentTarget.value += '\n';
                    return false;
                }
            }
            if(this.chatCommends[message.toLowerCase()]){
                const cmd = message.toLowerCase();
                if(this.chatCommends[cmd].type === 'STOP_CHAT_GPT'){
                    tmuWidget.stopChatGPT(true);
                    this.message = '';
                    if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
                    return;
                }
            }
            this.message = '';
            if(this.globals.isCustom)
                this.globals.blurMainInput();
            if(this.waitForNLPQuestion  || this.redirectToChatGPT){
                this.isChatInputEnabled = false;
                // this.widgetServices.hideAudioIcons()
                this.isBotTyping = true;
                this.waitForNLPQuestion = false
            }
            if(this.globals.messages[this.globals.messages.length - 1] && this.globals.messages[this.globals.messages.length - 1].choices){
                this.globals.messages[this.globals.messages.length - 1].choices.length = 0;
                this.globals.messages[this.globals.messages.length - 1].choicesSettings = null;
            }
            this.globals.onAddMessage({
                type: 'text',
                message: message,
                date: new Date().toLocaleTimeString('en-US'),
                from: 'user'
            }).then(async ()=>{
                if(this.mustTranslate && !this.redirectToChatGPT){
                    let translateResult = await this.translate({texts: [message], is_reversed: true})
                        .catch((error) => {console.log(error)});
                    if(Array.isArray(translateResult) && translateResult.length && translateResult[0].translation) {
                        this.talk(translateResult[0].translation);
                    } else this.talk(message);
                } else this.talk(message);
                this.message = '';
                if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
                setTimeout(() => { this.scrollDown(); })
            }, ()=>{});
        },
        talk(message, isRetried) {
            if (
                this.isRequestSent
                || typeof message !== 'string'
                || !message.trim()
                || message.length > this.globals.botInfo.maxMessageLength
            ) return;
            this.isRequestSent = true
            this.widgetServices.chatWithBot({
                text: message,
                lastNode: this.lastNode,
                cb: (data, err) => {
                    if(data) {
                        this.addToChat(data, message, isRetried).then(() => { setTimeout(() => { this.scrollDown(); }) }).catch(err => console.error(err))
                    } else console.log(err)
                    this.isRequestSent = false;
                }})
        },
        selectChoice(choice, i) {
            if(!this.globals.isChatEnabled || !this.isChatInputEnabled || this.isRequestSent || this.isBotTyping) return;
            this.disableChatInput = false;
            this.wizardInput = null;
            if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
            this.globals.selectChoice(choice);
            this.globals.onAddMessage({
                type: 'text',
                isChoice: true,
                message: choice.title,
                date: new Date().toLocaleTimeString('en-US'),
                from: 'user'
            }).then(()=>{
                if(this.globals.messages[i] && this.globals.messages[i].choices){
                    this.globals.messages[i].choices.length = 0;
                    this.globals.messages[i].choicesSettings = null;
                }
                setTimeout(() => { this.scrollDown(); })
                this.isRequestSent = true;
                this.widgetServices.chatWithBot({ text: choice.payload, lastNode: this.lastNode, cb: (data, err) => {
                        if(data){
                            this.addToChat(data).then(() => { setTimeout(() => { this.scrollDown(); })}).catch(err => console.error(err))
                        } else console.log(err)
                        this.isRequestSent = false;
                    }})
            }, ()=>{});
        },
        splitArrayIntoChunks(arr, len) {
            const chunks = [];
            let size = +len;
            for (let i = 0; i < arr.length; i += size) {
                chunks.push(arr.slice(i, i + size))
            }
            return chunks;
        },
        showMore(choicesSettings){
            if(choicesSettings.chunks.length && this.globals.messages[this.globals.messages.length - 1] && this.globals.messages[this.globals.messages.length - 1].choices){
                this.globals.messages[this.globals.messages.length - 1].choices = this.globals.messages[this.globals.messages.length - 1].choices
                    .concat(choicesSettings.chunks[0]);
                choicesSettings.chunks.shift();
                setTimeout(() => { this.scrollDown(); })
            }
        },
        openImageTab(img){
            if(!img || img.parentElement.tagName === 'A') return;
            if(img.src.indexOf('http') === 0){
                const w = window.open(img.src,'_blank');
                w.document.close();
                //  fetch(event.target.src)
                //     .then(response => response.blob())
                //     .then(blob => {
                //         const blobUrl = URL.createObjectURL(blob);
                //         window.open(blobUrl, '_blank');
                //     }).catch(() => {})
                return;
            }
            const base64ImageData = img.src;
            const imageInfoPart = base64ImageData.split(';')[0]
            if(typeof imageInfoPart !== "string" || !imageInfoPart.trim()) return;
            const contentType = imageInfoPart.split('data:')[1];
            const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) { byteNumbers[i] = slice.charCodeAt(i); }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, {type: contentType});
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        },
        showHideFullText(el){
            if(!el)
                return '';
            el.isFullText = !el.isFullText;
            setTimeout(() => { this.initImages(); }, 100)
        },
        HTML_substring(string, length) {
            let noHTML = string.replace(/<[^>]*>?/gm, ' ').replace(/\s+/g, ' ');
            let subStringNoHTML = noHTML.substr(0, noHTML.indexOf(" ", length));
            let words = subStringNoHTML.split(" ");
            let outPutString = "";
            let wordIndexes = [];

            words.forEach((word, key) => {
                if (key === 0) {
                    outPutString += string.substr(0, string.indexOf(word) + word.length);
                    wordIndexes[key] = string.indexOf(word) + word.length;
                } else {
                    let i = wordIndexes[key - 1];
                    outPutString += string.substring(i, string.indexOf(word, i) + word.length);
                    wordIndexes[key] = string.indexOf(word, i) + word.length;
                }
            });
            return outPutString;
        },
        getMessageText(el){
            if(!el)
                return '';
            if(!el.isFullText){
                return this.HTML_substring(el.message, +el.numberOfChars);
            }else { return el.message; }
        },
        async addToChat(response, message, isRetried) {
            try {
                if (!response || !response.success) return;
                response = response.reply;
                this.lastNode = response.lastNode;
                if(this.globals.botChatTimeout) clearTimeout(this.globals.botChatTimeout);
                if(!this.globals.botInfo.save_chat){
                    let timeoutMilliseconds = this.globals.botInfo.timeoutInterval * 60 * 1000;
                    this.globals.botChatTimeout = setTimeout(() => {
                        this.globals.isChatEnabled = false;
                        // this.widgetServices.hideAudioIcons()
                        this.globals.messages.push({
                            isEnd: true,
                            date: new Date().toLocaleTimeString('en-US'),
                            from: 'bot',
                            message: this.UIText.chat.botTimeout,
                            type: 'text',
                            isShowMoreEnabled: false,
                            isFullText: true,
                        });
                        this.globals.botChatTimeout = null;
                    }, timeoutMilliseconds)
                }
                const responses = response.responses;
                const suggestions = response.suggestions;
                if(Array.isArray(responses) && responses.length){ await this.addResponseToChat(responses) }
                else if(Array.isArray(suggestions) && suggestions.length){
                    const highestSuggest = suggestions.reduce((first, suggest) => (suggest.confidence > first.confidence ? suggest : first), suggestions[0]);
                    if(Array.isArray(highestSuggest.payloads) && highestSuggest.payloads.length){ await this.addResponseToChat(highestSuggest.payloads) }
                }
                else { if(typeof message === 'string' && message.trim() && !isRetried) this.talk(message, true); }
            } catch (err) { return console.log(err); }
            finally {
                if(this.redirectToChatGPT) {
                    this.isBotTyping = false;
                    this.isChatInputEnabled = true;
                    this.waitForNLPQuestion = false;
                    // this.widgetServices.setAudioToReady()
                }
            }
        },
        HTMLToPlainText(html){
            if(typeof html !== 'string') return;
            return html.replace(/<\/?[^>]+>/gi, ' ');
        },

        async addResponseToChat(responses, isHistory = false){
            const speakableText = [];
            const withoutGPTResponses = responses.filter(response => !response.isChatGPT);
            let indexWithoutGPT = -1;
            for (let [index, element] of Object.entries(responses)) {
                if(!element.isChatGPT) indexWithoutGPT++;
                if(element.isHidden) continue;
                const allowAction = +index === responses.length - 1 && isHistory;
                const allowHoldingAction = (
                    +index === responses.length - 2 &&
                    responses[responses.length - 1].component === 'QuickReplies' &&
                    isHistory
                );
                const allowHoldingGPTAction = (
                    indexWithoutGPT === withoutGPTResponses.length - 2 &&
                    withoutGPTResponses[withoutGPTResponses.length - 1].component === 'QuickReplies' &&
                    isHistory
                );
                if (!isHistory && (element.type === 'typing' || element.typing)) {
                    this.isBotTyping = true;
                    await new Promise(resolve => setTimeout(resolve, 1000))
                    this.isBotTyping = false;
                }
                if (element.type === 'text') {
                    let numberOfChars = 255;
                    let isShowMoreEnabled = false;
                    if(!this.globals.botInfo.isAuthenticated && /^\[\$#\[Roles:User]#\$]/.test(element.text)) {
                        if(!this.elementsOnHold.length){
                            this.globals.addMessage({
                                isEnd: true,
                                date: new Date().toLocaleTimeString('en-US'),
                                from: 'bot',
                                message: this.UIText.authenticationRequest,
                                type: 'text',
                                isShowMoreEnabled: false,
                                isFullText: true,
                            });
                        }
                        this.elementsOnHold.push(element.text);
                        continue;
                    } else {
                        element.text = element.text.replace(/^\[\$#\[Roles:User]#\$]/, '');
                    }
                    let getData = this.checkJson(element.text);
                    if(Array.isArray(getData) && getData.length){
                        const tableKey = getData.reduce((prev, curr) => {
                            if(typeof curr === 'object' && !Array.isArray(curr) && curr !== null){
                                prev = [...new Set([...Object.keys(curr)])]
                            }
                            return prev;
                        }, []);
                        this.globals.addMessage({
                            from: 'bot',
                            columns: tableKey,
                            data: getData,
                            type: 'table',
                            date: new Date().toLocaleTimeString('en-US')
                        });
                    } else {
                        let element_text = '';
                        if (getData) {
                            if (+getData.isPrivate) {
                                if (!isHistory && getData.action === "call") {
                                    this.widgetServices.disableLanguages();
                                    await new Promise(resolve => setTimeout(resolve, 1500))
                                    this.isGuestWaiting = true;
                                    // this.widgetServices.hideAudioIcons()
                                    let callInfo = {}
                                    // callInfo.requestId = authority.requestId;
                                    callInfo.guestName = getData.name || 'Guest';
                                    callInfo.subject = getData.subject || 'Call requested';
                                    callInfo.description = getData.description || `Call from ${this.globals.botInfo.origin}`;
                                    callInfo.providerId = getData.providerId || 0;
                                    this.callData = {
                                        guestName: callInfo.guestName,
                                        subject: callInfo.subject,
                                        description: callInfo.description
                                    }
                                    this.widgetServices.onCallRequested({
                                        providerId: callInfo.providerId,
                                        visitor_name: callInfo.guestName,
                                        subject: callInfo.subject,
                                        description: callInfo.description,
                                        cb: (data, err) => {
                                            console.log(data);
                                            console.log(err);
                                            if(err || !data) {
                                                this.widgetServices.enableLanguages();
                                                this.globals.addMessage({
                                                    isEnd: true,
                                                    date: new Date().toLocaleTimeString('en-US'),
                                                    from: 'bot',
                                                    message: this.UIText.call.callError,
                                                    type: 'text',
                                                    isShowMoreEnabled: false,
                                                    isFullText: true,
                                                });
                                                this.isGuestWaiting = false;
                                                // this.widgetServices.setAudioToReady()
                                                this.globals.valueToPassWaiting = "call is finished";
                                                this.resumeFlow();
                                            } else {
                                                if(data.success) {
                                                    callInfo.provider = data.provider;
                                                    console.log(callInfo.provider.config);
                                                    TMUCall.startMreCall(callInfo);
                                                    if(this.globals.botChatTimeout){
                                                        clearTimeout(this.globals.botChatTimeout);
                                                        this.globals.botChatTimeout = null;
                                                    }
                                                    this.refreshBotSessionInterval = setInterval(() => {
                                                        tmuWidget.chatWithBot({
                                                            text: '><',
                                                            ignoreSaving: true,
                                                            lastNode: this.lastNode,
                                                            cb: () => {}}
                                                        ) }, 30000);
                                                } else {
                                                    console.log(data.message);
                                                    this.isGuestWaiting = false;
                                                    // this.widgetServices.setAudioToReady()
                                                    this.globals.valueToPassWaiting = "call is finished";
                                                    this.resumeFlow();
                                                }
                                            }
                                        }
                                    })
                                    // tmuWidget.checkServiceAuthority({
                                    //     serviceName: 'call',
                                    //     cb: (data, err) => {
                                    //         if(err || !data) {
                                    //             this.globals.addMessage({
                                    //                 isEnd: true,
                                    //                 date: new Date().toLocaleTimeString('en-US'),
                                    //                 from: 'bot',
                                    //                 message: 'Sorry, we cannot satisfy your request for the moment, please retry in the next few minutes, thank you.',
                                    //                 type: 'text',
                                    //                 isShowMoreEnabled: false,
                                    //                 isFullText: true,
                                    //             });
                                    //             this.isGuestWaiting = false;
                                    //             this.globals.valueToPassWaiting = "call is finished";
                                    //             this.resumeFlow();
                                    //         } else {
                                    //             try {
                                    //                 const authority = JSON.parse(data);
                                    //                 if(authority.status === 'success'){
                                    //                     this.isGuestWaiting = true;
                                    //                     let callInfo = {}
                                    //                     callInfo.requestId = authority.requestId;
                                    //                     callInfo.guestName = getData.name || 'Guest';
                                    //                     callInfo.subject = getData.subject || 'Call requested';
                                    //                     callInfo.description = getData.description || `Call from ${this.globals.botInfo.origin}`;
                                    //                     callInfo.providerId = getData.providerId || 0;
                                    //                     this.callData = {
                                    //                         guestName: callInfo.guestName,
                                    //                         subject: callInfo.subject,
                                    //                         description: callInfo.description
                                    //                     }
                                    //                     this.widgetServices.onCallRequested({
                                    //                         providerId: callInfo.providerId,
                                    //                         visitor_name: callInfo.guestName,
                                    //                         subject: callInfo.subject,
                                    //                         description: callInfo.description,
                                    //                         cb: (data, err) => {
                                    //                             if(err || !data) {
                                    //                                 this.globals.addMessage({
                                    //                                     isEnd: true,
                                    //                                     date: new Date().toLocaleTimeString('en-US'),
                                    //                                     from: 'bot',
                                    //                                     message: 'Sorry, we cannot satisfy your request for the moment, please retry in the next few minutes, thank you.',
                                    //                                     type: 'text',
                                    //                                     isShowMoreEnabled: false,
                                    //                                     isFullText: true,
                                    //                                 });
                                    //                                 this.isGuestWaiting = false;
                                    //                                 this.globals.valueToPassWaiting = "call is finished";
                                    //                                 this.resumeFlow();
                                    //                             } else {
                                    //                                 try {
                                    //                                     callInfo.provider = JSON.parse(data)
                                    //                                     TMUCall.startMreCall(callInfo);
                                    //                                     if(this.globals.botChatTimeout){
                                    //                                         clearTimeout(this.globals.botChatTimeout);
                                    //                                         this.globals.botChatTimeout = null;
                                    //                                     }
                                    //                                     this.refreshBotSessionInterval = setInterval(() => { tmuWidget.chatWithBot({text: '><', cb: () => {}}) }, 30000);
                                    //                                 } catch (e){
                                    //                                     this.isGuestWaiting = false;
                                    //                                     this.globals.valueToPassWaiting = "call is finished";
                                    //                                     this.resumeFlow();
                                    //                                 }
                                    //                             }
                                    //                         }
                                    //                     })
                                    //                 } else {
                                    //                     if(authority.reason === 'locked'){
                                    //                         this.globals.addMessage({
                                    //                             isEnd: true,
                                    //                             date: new Date().toLocaleTimeString('en-US'),
                                    //                             from: 'bot',
                                    //                             message: 'Sorry. the agent is busy with different call right now, Please try again later in few minutes',
                                    //                             type: 'text',
                                    //                             isShowMoreEnabled: false,
                                    //                             isFullText: true,
                                    //                         });
                                    //                         this.isGuestWaiting = false;
                                    //                         this.globals.valueToPassWaiting = "call is finished";
                                    //                         this.resumeFlow();
                                    //                     }
                                    //                 }
                                    //             }catch(e) {
                                    //                 console.log(e);
                                    //                 this.isGuestWaiting = false;
                                    //                 this.globals.valueToPassWaiting = "call is finished";
                                    //                 this.resumeFlow();
                                    //             }
                                    //         }
                                    //     }
                                    // })
                                }
                                else if (!isHistory && getData.action === "transcription") {
                                    this.showTranscriptModal();
                                }
                                else if ((!isHistory || allowHoldingAction) && getData.action === "feedback") {
                                    setTimeout(() => {
                                        this.widgetServices.getFeedbacksList({
                                            feedbackId: getData.feedbackCode, cb: async (data, err) => {
                                                if(err || !data) return;
                                                if(data.success){
                                                    this.isBotTyping = true;
                                                    await new Promise(resolve => setTimeout(resolve, 1000));
                                                    this.isBotTyping = false;
                                                    await this.showFeedbackModal(data.questionList);
                                                } else console.log(data.message);
                                            }})
                                    })

                                }
                                else if ((!isHistory || allowHoldingGPTAction) && getData.action === "gpt") {
                                    this.redirectToChatGPT = true;
                                    tmuWidget.setupForChatGPT();
                                }
                                else if ((!isHistory || allowAction) && getData.action === "waiting") {
                                    if (getData.isTyping) this.isBotTyping = true;
                                    await new Promise(resolve => setTimeout(resolve, +getData.duration * 1000))
                                    if (getData.isTyping) this.isBotTyping = false;
                                }
                                else if((!isHistory || allowAction) && getData.action === "dataExtractor"){
                                    let chatbox = document.querySelector('.chatbox .textInput');
                                    let dateInput = document.querySelector('.chatbox .dateInput');
                                    let fileInput = document.querySelector('.chatbox .fileInput');
                                    if(getData.type === 'time'){
                                        if(chatbox){
                                            this.isRecordingAllowed = false;
                                            chatbox['style'].display = 'none';
                                            if(dateInput) dateInput['style'].display = 'block';
                                            if(fileInput) fileInput['style'].display = 'none';
                                        }
                                    } else if(getData.type === 'file'){
                                        if(chatbox){
                                            this.isRecordingAllowed = false;
                                            chatbox['style'].display = 'none';
                                            if(dateInput) dateInput['style'].display = 'none';
                                            if(fileInput) fileInput['style'].display = 'block';
                                            switch (getData.format) {
                                                case 'image':
                                                    this.requestedInputFormat = 'image/jpeg'; //'image/jpeg, image/png'
                                                    break;
                                                case 'pdf':
                                                    this.requestedInputFormat = 'application/pdf';
                                                    break
                                            }
                                        }
                                    } else {
                                        this.isRecordingAllowed = true;
                                        if(chatbox) chatbox['style'].display = 'block';
                                        if(dateInput) dateInput['style'].display = 'none';
                                        if(fileInput) fileInput['style'].display = 'none';
                                    }
                                }
                                else if((!isHistory || allowAction) && getData.action === "wizard"){
                                    const wizard_text = this.replaceTTSPatternForText(getData.feedback)
                                    if(getData.type === 'options' || getData.type === 'boolean'){
                                        this.disableChatInput = true;
                                        const sanitizedChoices = getData.options.map(option => ({title: {labelText: this.replaceTTSPatternForText(option.label)}, payload: option.value}))
                                        const choices = getData.options.map(option => ({title: {labelText: option.label}, payload: option.value}))
                                        this.globals.addMessage({
                                            from: 'bot',
                                            message: wizard_text,
                                            type: 'choices',
                                            date: element.created_at ? new Date(element.created_at / 10).toLocaleTimeString('en-US') : new Date().toLocaleTimeString('en-US'),
                                            choicesText: '\n'+ sanitizedChoices.map(c => `[${c.title.labelText}]`).join('\n'),
                                            choices: sanitizedChoices
                                        });
                                        if((!isHistory || (isHistory && !this.lastNode)) && this.globals.botInfo.isAudioEnabled && this.isSpeakerOn) speakableText.push(getData.feedback + '.\n' +choices.map(c => `[${c.title.labelText}]`).join('. '))
                                    }
                                    else {
                                        this.globals.addMessage({
                                            from: 'bot',
                                            message: wizard_text,
                                            type: 'text',
                                            date: element.created_at ? new Date(element.created_at / 10).toLocaleTimeString('en-US') : new Date().toLocaleTimeString('en-US'),
                                            isShowMoreEnabled: false,
                                            isFullText: true,
                                        });
                                        if(getData.type === 'int') this.wizardInput = 'int';
                                        if(getData.type === 'email') this.wizardInput = 'email';
                                        if(getData.type === 'date') this.wizardInput = 'date';
                                        if(getData.type === 'datetime') this.wizardInput = 'datetime';
                                        if((!isHistory || (isHistory && !this.lastNode)) && this.globals.botInfo.isAudioEnabled && this.isSpeakerOn) speakableText.push(getData.feedback)
                                    }
                                }
                                else if((!isHistory || allowAction) && getData.action === "NLPAnswerStarts"){
                                    this.waitForNLPQuestion = true;
                                }
                                else if((!isHistory || allowAction) && getData.action === "NLPAnswerEnds"){
                                    this.isBotTyping = false;
                                    this.isChatInputEnabled = true;
                                    this.waitForNLPQuestion = false;
                                    // this.widgetServices.setAudioToReady()
                                }
                                else if((!isHistory || allowAction) && getData.action === "geolocation"){
                                    this.widgetServices.sendGeoLocationPosition((data, err) => {
                                        if(data){ this.addToChat(data).then(() => {}).catch(() => {});}
                                        else console.log(err)
                                    })
                                }
                                else if((!isHistory || allowAction) && getData.action === "exit"){
                                    this.globals.isChatEnabled = false
                                    if(this.globals.botChatTimeout) clearTimeout(this.globals.botChatTimeout);
                                    this.globals.botChatTimeout = null;
                                    this.isChatInputEnabled = false;
                                    // this.widgetServices.hideAudioIcons()
                                }
                                setTimeout(() => { this.scrollDown(); })
                                continue;
                            }
                            if('show_more' in getData) {
                                try {
                                    let textInfo = JSON.parse(getData.show_more);
                                    if (textInfo.is_enabled) {
                                        isShowMoreEnabled = textInfo.is_enabled;
                                        numberOfChars = textInfo.number;
                                    }
                                } catch (e) {console.log(e)}
                            }
                            element_text = ('html' in getData) ? getData.html : ('text' in getData) ? getData.text : element.text;
                        } else { element_text = element.text; }
                        if((!isHistory || (isHistory && !this.lastNode)) && this.globals.botInfo.isAudioEnabled && this.isSpeakerOn) speakableText.push(element_text)
                        element_text = this.replaceTTSPatternForText(element_text)
                        if(!element.from || element.from === 'bot'){
                            this.globals.addMessage({
                                type: 'text',
                                message: element_text,
                                date: element.created_at ? new Date(element.created_at / 10).toLocaleTimeString('en-US') : new Date().toLocaleTimeString('en-US'),
                                isShowMoreEnabled: isShowMoreEnabled && this.HTML_substring(element_text, numberOfChars).length > numberOfChars,
                                isFullText: !(isShowMoreEnabled && this.HTML_substring(element_text, numberOfChars).length > numberOfChars),
                                numberOfChars: numberOfChars,
                                from: 'bot'
                            })
                        }
                        else {
                            this.globals.addMessage({
                                type: 'text',
                                message: element_text,
                                date: new Date().toLocaleTimeString('en-US'),
                                from: 'user'
                            })
                        }
                    }
                }
                if (element.type === 'custom' && element.component === 'QuickReplies') {
                    for(const c of element.quick_replies){
                        try{
                            const labelObj = JSON.parse(c.title.replace(/”/gi, "\""))
                            if('labelText' in labelObj) c.title = labelObj;
                            else c.title = {labelText: c.title, labelIcon: null, isTextHidden: false}
                        } catch (e) {
                            c.title = {labelText: c.title, labelIcon: null, isTextHidden: false}
                        }
                    }
                    let choicesSettings = null;
                    let element_text = '';
                    let getData = this.checkJson(element['wrapped']['text']);
                    element_text = getData && ('html' in getData) ? getData.html : ('text' in getData) ? getData.text : element['wrapped']['text'];
                    if(getData){
                        if (+getData.isPrivate) {
                            if (
                                ((!isHistory || allowAction) && (getData.action === "after_call_finish" || getData.action === 'after_feedback_finish' || getData.action === "after_transcription_finish")) ||
                                ((!isHistory || (isHistory && +indexWithoutGPT === withoutGPTResponses.length - 1)) && getData.action === "after_gpt_finish" )
                            ) {
                                this.globals.valueToPassWaiting = getData.valueToPass;
                                if(this.elementsOnHold.length || (isHistory && +index === responses.length - 1 && (getData.action === "after_call_finish" || getData.action === "after_transcription_finish"))) { this.resumeFlow(); }
                            }
                            continue;
                        }
                        if('show_more' in getData){
                            try {
                                let choicesInfo = JSON.parse(getData.show_more);
                                if(choicesInfo.is_enabled){
                                    choicesSettings = {};
                                    choicesSettings.chunks = this.splitArrayIntoChunks(element.quick_replies.slice(), choicesInfo.number);
                                    choicesSettings.choices = choicesSettings.chunks[0].slice();
                                    choicesSettings.chunks.shift();
                                }
                            }
                            catch(e) { choicesSettings = null; }
                        }
                    }
                    if((!isHistory || (isHistory && !this.lastNode)) && this.globals.botInfo.isAudioEnabled && this.isSpeakerOn) speakableText.push(element_text + '.\n' +element.quick_replies.map(c => `[${c.title.labelText}]`).join('. '))
                    element_text = this.replaceTTSPatternForText(element_text)
                    if(choicesSettings) for(const c of choicesSettings.choices){ c.title.labelText = this.replaceTTSPatternForText(c.title.labelText) }
                    else for(const c of element.quick_replies){ c.title.labelText = this.replaceTTSPatternForText(c.title.labelText) }
                    const choicesItem = {
                        type: 'choices',
                        message: element_text,
                        date: element.created_at ? new Date(element.created_at / 10).toLocaleTimeString('en-US') : new Date().toLocaleTimeString('en-US'),
                        from: 'bot',
                        choicesSettings: choicesSettings,
                        choices: choicesSettings ? choicesSettings.choices : element.quick_replies,
                        choicesText: '\n'+ element.quick_replies.map(c => `[${c.title && c.title.labelText ? c.title.labelText : c.title}]`).join('\n')
                    }
                    if(isHistory && +index < responses.length - 1){
                        choicesItem.choices.length = 0;
                        choicesItem.choicesSettings = null;
                    }
                    this.globals.messages.push(choicesItem)
                }
                if (element.type === 'carousel') {
                    let elements = element.elements.map(elem => {
                        let titleData = this.checkJson(elem.title);
                        let subtitleData = this.checkJson(elem.subtitle);
                        return {
                            title: titleData && 'html' in titleData ? titleData.html : elem.title,
                            subtitle: subtitleData && 'html' in subtitleData ? subtitleData.html : elem.subtitle
                        }
                    })
                    if((!isHistory || (isHistory && !this.lastNode)) && this.globals.botInfo.isAudioEnabled && this.isSpeakerOn) speakableText.push(elements.map(element => 'Question: '+element.title + '. ' + 'Answer: '+element.subtitle).join('.\n'))
                    const qna = [...elements.map(element => ({
                        title: this.replaceTTSPatternForText(element.title),
                        subtitle: this.replaceTTSPatternForText(element.subtitle)
                    }))]
                    this.globals.addMessage({
                        type: 'qna',
                        date: element.created_at ? new Date(element.created_at / 10).toLocaleTimeString('en-US') : new Date().toLocaleTimeString('en-US'),
                        from: 'bot',
                        qna: qna,
                        qnaText: elements.map(element => '<b>Question:</b> '+element.title + '\n' + '<b>Answer:</b> '+element.subtitle).join('\n\n')
                    })
                }
                if (element.type === 'file') {
                    this.getImageData(element.url).then(src => {
                        this.globals.addMessage({
                            type: 'image',
                            url: src,
                            title: element.title,
                            date: element.created_at ? new Date(element.created_at / 10).toLocaleTimeString('en-US') : new Date().toLocaleTimeString('en-US'),
                            from: 'bot'
                        })
                    }).then(() => {})
                }
                setTimeout(() => {
                    this.scrollDown();
                    this.initImages();
                }, 100)
            }
            if(this.globals.messages.length > 0 && !this.isConvMenuReady){
                this.widgetServices.showConvOptions()
                this.isConvMenuReady = true;
            }
            if((!isHistory || (isHistory && !this.lastNode)) && this.globals.botInfo.isAudioEnabled && this.isSpeakerOn) this.runTextToSpeech(speakableText.join('.\n'));
        },
        initImages(){
            document.querySelectorAll('.conversation .chat-content p > img:first-child').forEach(img => {
                let hoverIcon = img.parentNode.querySelector('.hover-icon');
                if (hoverIcon) return;
                this.wrapImageInParagraph(img);
                img.parentNode['style'].position = 'relative';
                img.parentNode['style'].lineHeight = '0';
                hoverIcon = document.createElement('div');
                hoverIcon.className = 'hover-icon';
                hoverIcon.innerHTML =
                    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" /></svg>`;

                const overlay = document.createElement('div');
                overlay.className = 'overlay-icon';
                img.parentNode.appendChild(overlay);
                img.parentNode.appendChild(hoverIcon);
                img.addEventListener('click', e => {
                    hoverIcon.style.display = 'block';
                    overlay.style.display = 'block';
                    setTimeout(()=> {
                        overlay.style.display = 'none';
                        hoverIcon.style.display = 'none';
                    }, 7000)
                });
                overlay.addEventListener('click', e => {
                    this.openImageTab(img);
                    overlay.style.display = 'none';
                    hoverIcon.style.display = 'none';
                })
            });
        },
        isImageOnlyChild(img) {
            const parent = img.parentNode;
            return parent.childElementCount === 1 && parent.firstChild === img;
        },
        wrapImageInParagraph(img) {
            const parent = img.parentNode;
            if (!this.isImageOnlyChild(img)) {
                const paragraph = document.createElement('p');
                parent.replaceChild(paragraph, img);
                paragraph['style'].display = 'inline-block';
                paragraph.appendChild(img);
            }
        },
        getNewUser() {
            if(this.isEmailModalShown || this.isDownloadModalShown || this.isDownloadSending || this.isFeedbackSending) return;
            if(this.globals.botInfo.isAudioEnabled) this.stopTTS();
            this.redirectToChatGPT = false;
            this.closeFeedbackModal(false);
            tmuWidget.stopChatGPT(false);
            this.closeTranscriptModal(false);
            this.globals.messages = [];
            this.isChatInputEnabled = true;
            this.waitForNLPQuestion = false;
            this.isBotTyping = false;
            this.lastNode = undefined;
            if(this.globals.botChatTimeout) clearTimeout(this.globals.botChatTimeout);
            this.globals.botChatTimeout = null;
            this.globals.isChatEnabled = true;
            this.disableChatInput = false;
            this.wizardInput = null;
            this.isGuestWaiting = false;
            // this.widgetServices.setAudioToReady()
            this.isConvMenuReady = false;
            this.widgetServices.hideConvOptions();
            if(this.isWidgetReady){
                this.widgetServices.startNewConversation({
                    cb: (data, err) => {
                        if(data){ this.addToChat(data).then(() => {}).catch(() => {});}
                        else console.log(err)
                    }
                })
            }
        },
        toggleSaySomething(text) {
            this.globals.addMessage({
                type: 'text',
                message: text,
                date: new Date().toLocaleTimeString('en-US'),
                from: 'bot'
            });
            setTimeout(() => { this.scrollDown(); })
        },
        showQnAAnswer(event) {
            let arrowElem = event.currentTarget.querySelector('.qna-title .arrow')
            if (arrowElem.classList.contains('mdi-chevron-down')) { arrowElem.className = 'mdi mdi-chevron-up arrow';}
            else { arrowElem.className = 'mdi mdi-chevron-down arrow'; }
            let subtitle = event.currentTarget.querySelector('.subtitle');
            if (!subtitle.style.display || subtitle.style.display === 'none') { subtitle.style.display = 'block'; }
            else { subtitle.style.display = 'none'; }
        },
        async showFeedbackModal(data) {
            this.isFeedbackShown = true;
            let feedback_to_translate = []
            let translated_feedback = [];
            let feedbackIndex = 0;
            if(this.mustTranslate){
                feedback_to_translate = data.questionList.feedbackList.element.reduce((prev, cur) => {
                    prev.push(cur.question,  ...cur.options)
                    return prev;
                }, []);
                translated_feedback = await this.translate({texts: [data.questionList.afterSubmit.element, ...feedback_to_translate]})
                    .catch((error) => {console.log(error)})
                if(Array.isArray(translated_feedback) && translated_feedback.length){
                    data.questionList.afterSubmit.element = translated_feedback.shift().translation || data.questionList.afterSubmit.element;
                    data.questionList.feedbackList.element.forEach(elem => {
                        if(translated_feedback[feedbackIndex].translation) elem.question = translated_feedback[feedbackIndex].translation;
                        feedbackIndex++;
                        elem.options.forEach((o, i) => {
                            if(translated_feedback[feedbackIndex].translation) elem.options[i] = translated_feedback[feedbackIndex].translation;
                            feedbackIndex++;
                        })
                    })
                }
            }
            data.questionList.feedbackList.element = data.questionList.feedbackList.element.map(elem => {
                if (elem.answerType === 'text') return {...elem, answerText: ''};
                else if (elem.answerType === 'one-select') return {...elem, selectedOption: ''};
                else if (elem.answerType === 'multi-select') return {...elem, selectedOptions: []};
            });
            this.feedbackData = data;
        },
        addOption(event, i) {
            if (event.target.checked) {
                this.feedbackData.questionList.feedbackList.element[this.currentFeedbackQuestion].selectedOptions.push(this.feedbackData.questionList.feedbackList.element[this.currentFeedbackQuestion].options[i])
            } else {
                this.feedbackData.questionList.feedbackList.element[this.currentFeedbackQuestion].selectedOptions.splice(i, 1);

            }
        },
        nextFeedback() {
            if (this.currentFeedbackQuestion >= this.feedbackData.questionList.feedbackList.element.length - 1) return;
            this.currentFeedbackQuestion++;
        },
        closeFeedbackModal(continueConversation = true) {
            this.isFeedbackShown = false;
            this.feedbackData = null;
            this.currentFeedbackQuestion = 0;
            this.isFeedbackSending = false;
            this.isFeedbackSent = false;
            if(continueConversation && this.globals.valueToPassWaiting && this.isChatInputEnabled && this.globals.isChatEnabled) {
                this.resumeAfterFlowPause(this.globals.valueToPassWaiting);
                this.globals.valueToPassWaiting = '';
            }
        },
        waitAgentMore() {
            this.isEmailModalShown = false;
            clearTimeout(this.waitingGuestTracker);
            this.waitingGuestTracker = setTimeout(() => {
                this.isEmailModalShown = true;
                this.isGuestWaiting = true;
                // this.widgetServices.hideAudioIcons()
            }, this.guestSecondsOfWaiting);
        },
        sendFeedback() {
            if (!this.feedbackData) { this.closeFeedbackModal(); return }
            this.isFeedbackSending = true;
            this.isFeedbackSent = false;
            let qnaFeedback = this.feedbackData.questionList.feedbackList.element.map(elem => {
                let data = {};
                data['question'] = elem.question;
                if (elem.answerType === 'multi-select') data['answer'] = elem.selectedOptions.join(', ');
                if (elem.answerType === 'one-select') data['answer'] = elem.selectedOption;
                if (elem.answerType === 'text') data['answer'] = elem.answerText.trim();
                return data;
            })
            this.widgetServices.sendFeedbackAnswers({
                feedbackId: this.feedbackData.id, qnaFeedback, cb: () => {
                    this.isFeedbackSending = false;
                    this.isFeedbackSent = true;
                    setTimeout(() => { this.closeFeedbackModal();}, 5000);
                }
            })
        },
        sendGuestEmail() {
            if (this.emailText === "") return;
            this.isFeedbackSending = true;
            this.widgetServices.onGuestWaited({
                guest_name: this.callData.guestName,
                subject: this.callData.subject,
                description: this.callData.description,
                email: this.emailText,
                conversation: this.getConversationString(),
                cb: (data, err) => {
                    this.isFeedbackSending = false;
                    if(err || !data) return;
                    if(data){
                        this.emailSendInfo = data;
                        this.emailSendInfo.message = this.emailSendInfo.success ? this.UIText.call.userWaitingSuccess : this.UIText.call.userWaitingFailure;
                        if (!this.emailSendInfo || !this.emailSendInfo.success) return console.error(this.emailSendInfo.message);
                        this.isEmailSent = true;
                        TMUCall.stopMreCall().catch(err => console.error(err)).finally(() => {
                            this.widgetServices.enableLanguages();
                            this.isGuestWaiting = false;
                            // this.widgetServices.setAudioToReady()
                            this.isEmailModalShown = false;
                            this.isEmailSent = false;
                            this.callData = null;
                            this.emailText = "";
                            this.emailSendInfo = null;
                            this.globals.addMessage({
                                type: 'text',
                                message: this.UIText.call.callClosed,
                                date: new Date().toLocaleTimeString('en-US'),
                                from: 'bot',
                                isFullText: true,
                            })
                            if(this.refreshBotSessionInterval){
                                clearInterval(this.refreshBotSessionInterval);
                                this.refreshBotSessionInterval = null;
                            }
                            this.resumeFlow();
                        })
                    }
                    else console.log(err)
                }
            })
        },
        sendTranscript() {
            if (this.downloadEmailText === "") return;
            this.isDownloadSending = true;
            this.widgetServices.downloadConversation({
                email: this.downloadEmailText, conversation: this.getConversationString(), cb: (data, err) => {
                    this.isDownloadSending = false;
                    if(data){
                        this.downloadSendInfo = data;
                        this.downloadSendInfo.message = this.downloadSendInfo.success ? this.UIText.emailConvSuccess : this.UIText.emailConvFailure;
                        this.isDownloadSent = true;
                        setTimeout(() => {
                            this.isDownloadModalShown = false;
                            this.downloadEmailText = "";
                            this.downloadSendInfo = null;
                        }, 5000);
                    }
                    else console.log(err)
                }
            })
        },
        getConversationString(){
            return this.globals.messages.filter(message => message && !message.isEnd && (message.message || message.qnaText)).map(message => {
                let from = message.from === 'bot' ? 'Bot' : 'You';
                let choices = '';
                let messageText = (message.message && message.message.labelText ? message.message.labelText : message.message) || message.qnaText;
                if (message.choicesText) { choices = message.choicesText; }
                return `${message.date + ' UTC'} [${from}]\n${(messageText.replace(/<img[^>]*>/g,"[image]") + choices).replace(/<p>/g, '').replace(/<\/p>/g, '').trim()}`;
            }).filter(message => message).join('\n\n')
        },
        showDownloadModal() {
            this.isDownloadSent = false;
            this.isDownloadModalShown = true;
        },
        selectFile($event){
            this.selectedChatFile = $event.target;
        },
        sendBotFile(){
            // 10MB and valid format
            if(!this.selectedChatFile || this.selectedChatFile.files[0].size > 1e+7 || this.selectedChatFile.files[0].type !== this.requestedInputFormat) return;
            let stringArr = this.selectedChatFile.files[0].name.split('.');
            let ext = stringArr[stringArr.length - 1];

            const fr = new FileReader()
            fr.readAsArrayBuffer(this.selectedChatFile.files[0])
            fr.onload = () => {
                const blob = new Blob([fr.result])
                this.widgetServices.sendBotFile({blob, ext, cb: (data, error) => {
                        if(data){ this.addToChat(data).then(() => {}).catch(() => {});}
                        else console.log(error)
                    }})
            }

            this.globals.addMessage({
                from: 'user',
                type: 'text',
                message: 'File sent',
                date: new Date().toLocaleTimeString('en-US')
            });
            this.requestedInputFormat = '';
            this.selectedChatFile.value = '';
            this.selectedChatFile = null;
        },
        translate({texts = [], is_reversed, from, to}){
            texts = texts.map(text => this.removeEmojis(text));
            return fetch(process.env.VUE_APP_TRANSLATE_TEXT_HOST, {
                method: 'POST',
                body: JSON.stringify({
                    text: texts,
                    from_lang: !is_reversed ? from || this.globals.botInfo.language_code : to || this.speechLang,
                    to_lang: !is_reversed ? to || this.speechLang : from || this.globals.botInfo.language_code
                }),
                headers: {'Content-Type': 'application/json'}
            }).then(res => {
                if(res.ok) return res.json();
                throw '"Unable to translate'
            })
                .then(res => {
                    if(res && res.success && Array.isArray(res.texts)){
                        return res.texts.map((text, index) => (
                            {index: index, original: texts[index], translation: text.success ? res.texts[index].text : undefined}
                        ))
                    } else return []
                }).catch(error => error)
        }
    },
}
