/*eslint-disable*/
import widgetServices from './tmuWidget'
import {globals} from "@/globals";

const TMUCall = (function(){

    let _requestId = null;
    let _AccountProfile = {};
    let _SelectedEndpoint = {};
    let _MreCallJoinCode = null;
    let _MreCall = null;
    let _callId = null;
    let _MreCallProvider = {};
    let _AccessToken = null;
    let ROLE_HOST_PARTICIPANT = 'af7c0f9c-5966-46f2-9c6d-eb1f0e4fe6b2';
    let ROLE_MODERATOR_PARTICIPANT = `f51179f1-8af7-45f9-a480-7063c73ee5d7`;

    function onPromoteParticipant(call, participant, target) {
        return new Promise((resolve, reject) => {
            if(!participant || !target || !call || !call.mre_call_id) {
                resolve();
                return;
            }
            let payload = {
                MreCallProvider: _MreCallProvider,
                MreCallParticipant: participant,
                TargetMreCallParticipant: target
            };
            let url = _SelectedEndpoint.api+'/api/call/'+call.mre_call_id+'/promoteparticipant';
            fetch(url, {
                method: 'POST',
                headers: { Authorization: 'Bearer '+ _AccessToken },
                body: JSON.stringify(payload)
            })
            .then((response)=>{
                response.json()
                .then((res)=>{
                    resolve(res);
                }, (error)=>{
                    reject(error);
                });
            }, (error)=>{
                reject(error);
            });
        });
    }
    function _checkCallStarted(opt) {
        let shouldCheck = false;
        onLoadExpandedCall(opt.MreCall, {participants: 1})
        .then(res =>{
            if(res && Array.isArray(res.MreCallParticipants)) {
                const participants = res.MreCallParticipants.filter(item => item.last_active_time && item.is_active);
                if (participants.length > 0) {
                    const callInfo = JSON.stringify({
                        isEmergency: opt.isEmergency,
                        callToken: opt.MreCallJoinCode,
                        guestName: opt.guestName,
                        callId: _callId,
                        profileId: _AccountProfile.account_profile_id,
                        endpoint: _SelectedEndpoint.name,
                        mreCallProviderId: _MreCallProvider.mre_call_provider_id,
                    });
                    let currentHost = res.MreCallParticipants.filter(item => item.role_id === ROLE_HOST_PARTICIPANT);
                    currentHost = currentHost.length === 1 ? currentHost[0] : null;
                    let target = null;
                    if (currentHost && (!currentHost.last_active_time || !currentHost.is_active)) {
                        //get agents from participants
                        const agents = participants.filter(item=> item.role_id === ROLE_MODERATOR_PARTICIPANT);
                        target = agents.reduce((prev, cur) => prev.last_active_time < cur.last_active_time ? cur : prev, agents[0]);
                    }
                    if(target && (target.account_profile_id === _AccountProfile.account_profile_id || currentHost && currentHost.account_profile_id===target.account_profile_id))
                        target = null;
                    onPromoteParticipant(_MreCall, currentHost, target)
                        .then(() => {}, () => {})
                        .finally(() => { widgetServices.redirectToCall({ callInfo, cb: (data, err) => { console.log({data, err}) } }) });
                    return;
                }
            }
            shouldCheck = !!(res && !Array.isArray(res.errors) && res.MreCall);
        }, (error)=>{ shouldCheck = false; })
        .finally(()=>{
            if(shouldCheck) setTimeout(() => { _checkCallStarted(opt);}, 2000);})
    }
    function onLoadExpandedCall(call, params) {
        return new Promise((resolve, reject) => {
            if(!call) {
                reject();
                return;
            }
            if(!_MreCallProvider.mre_call_provider_id) {
                reject();
                return;
            }
            let retry = 1;
            function loadExpanded(){
                let url = _SelectedEndpoint.api+'/api/call/'+call.mre_call_id+'?providerId='+_MreCallProvider.mre_call_provider_id;
                if(params && typeof params == 'object')
                    for(let k in params)
                        if(params[k])
                            url += ('&'+k+'='+params[k])
                fetch(url, {
                    method: 'GET',
                    headers: { Authorization: 'Bearer '+ _AccessToken }
                })
                .then((response)=>{
                    response.json()
                    .then((res)=>{
                        if (Array.isArray(res.errors)) {
                            reject(res.errors.filter(item=>item.key==='client').map(item=>item.message).join(' '));
                            return;
                        }
                        resolve(res);
                    }, (error)=>{
                        reject(error);
                    });
                }, (error)=>{
                    if(retry>2)
                        reject(error);
                    else
                        setTimeout(()=>{
                            retry++;
                            loadExpanded();
                        }, 1000)
                });
            }
            loadExpanded();
        });
    }
    function onScheduleCall(obj) {
        return new Promise((resolve, reject)=>{
            if(!obj) {
                reject();
                return;
            }
            let payload = {
                MreCallProvider: _MreCallProvider,
                AccessToken: _AccessToken,
                CallSubject: obj.subject || '',
                CallDescription: obj.description || '',
                CallAllAvailableAgentsAutomatically: true,
                Endpoint: _SelectedEndpoint.name,
                ScheduleDate: new Date().toISOString(),
                AllowJoinWithoutHost: true,
                RecordCall: obj.isRecorded,
                TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                // Duration: ,
                ShowMyEmailOnInvite: true
            };

            fetch(_SelectedEndpoint.api+'/api/call/schedule', {
                method: 'POST',
                headers: { Authorization: 'Bearer '+ _AccessToken },
                body: JSON.stringify(payload)
            }).then(response => { response.json().then((res) => { resolve(res); }, (error)=>{ reject(error); });}, (error) => { reject(error); });
        });
    }
    function startMreCall(callInfo){
        if(!callInfo || !callInfo.provider || !+callInfo.provider.provider_id) return;
        let provider =  null;
        try{ provider = JSON.parse(callInfo.provider.config); }
        catch(e) { console.error( 'provider.config cannot be parsed'); }
        //TODO handle error missing provider
        if(!provider) return;
        _MreCallProvider = {mre_call_provider_id: provider.mre_call_provider_id};
        if(callInfo.provider.endpoint === 'local') {
            _SelectedEndpoint = { endpoint: "ws://localhost:15674/ws", username: "admin", password: "admin", name: "local", displayName: "Local", api: "http://localhost:8000"}
        } else if(callInfo.provider.endpoint === 'alpha') {
            _SelectedEndpoint = {endpoint: "wss://alpha-sassy-mq.mresence.com:15673/ws", username: "mresence", password: "mresence123", name: "alpha", displayName: "Alpha", api: "https://alpha-rest-api.mresence.com"};
        } else {
            _SelectedEndpoint = { endpoint: "wss://dev-sassy-mq.mresence.com:15673/ws", username: "mresence", password: "mresence123", name: "dev", displayName: "Dev", api: "https://dev-rest-api.mresence.com"}
        }

        _callId = callInfo.provider.callId;
        _AccessToken = callInfo.provider.access_token;
        _requestId = callInfo.requestId;
        _AccountProfile = { account_profile_id: callInfo.provider.profile_id };
        onScheduleCall({subject: callInfo.subject, description: callInfo.description, isRecorded: callInfo.isEmergency}).then((res)=>{
            if(Array.isArray(res.errors)) return console.error('ScheduleMreCall:', res.errors.filter(item=>item.key==='client').map(item=>item.message).join(' '));
            _MreCallJoinCode = res.MreCall.join_code;
            _MreCall = res.MreCall;
            globals.BotPage.waitAgentMore();
            _checkCallStarted({MreCall: _MreCall, MreCallJoinCode: _MreCallJoinCode, guestName: callInfo.guestName, isEmergency: callInfo.isEmergency});
        }, (error)=>{});
    }
    function stopMreCall() {
        return new Promise((resolve, reject)=>{
            if(!_MreCall || !_MreCall.mre_call_id) {
                reject();
                return;
            }
            let payload = { MreCallProvider: _MreCallProvider, MreCall: _MreCall};
            let url = _SelectedEndpoint.api+'/api/call/'+_MreCall.mre_call_id+'/stop';
            fetch(url, {
                method: 'POST',
                headers: { Authorization: 'Bearer '+ _AccessToken },
                body: JSON.stringify(payload)
            })
            .then((response)=>{
                response.json()
                .then((res)=>{
                    if (Array.isArray(res.errors)) {
                        reject(res.errors.filter(item=>item.key==='client').map(item=>item.message).join(' '));
                    } else {
                        resolve(res);
                    }
                }, (error)=>{
                    reject(error);
                });
            }, (error)=>{
                reject(error);
            });
        });
    }
    return { startMreCall, stopMreCall }
})();

export {TMUCall}
